import logo from './logo.svg';
import './App.css';
import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar } from "./components/NavBar";
import { BannerHome } from "./components/BannerHome";
import { BannerMetodi } from "./components/BannerMetodi";
import { BannerSondaggiRetribuiti } from "./components/BannerSondaggiRetribuiti";
import { BannerAffiliateMarketing } from "./components/BannerAffiliateMarketing";
import { Footer } from "./components/Footer";

function App() {

  switch (window.location.pathname){
    case "/":
      return (
        <div className="App">
          <NavBar />
          <BannerHome />
          <Footer />
        </div>
      );
      break
    case "/home":
      return (
        <div className="App">
          <NavBar />
          <BannerHome />
          <Footer />
        </div>
      );
      break
    case "/metodi":
      return (
        <div className="App">
          <NavBar />
          <BannerMetodi />
          <Footer />
        </div>
      );
      break
      case "/SondaggiRetribuiti":
      return (
        <div className="App">
          <NavBar />
          <BannerSondaggiRetribuiti />
          <Footer />
        </div>
      );
      break
      case "/AffiliateMarketing":
      return (
        <div className="App">
          <NavBar />
          <BannerAffiliateMarketing />
          <Footer />
        </div>
      );
      break
  }
  
}

export default App;
