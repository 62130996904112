
import { Container, Row, Col } from "react-bootstrap";
import uanama from "../assets/img/uanama.jpg";
import ImGoD from "../assets/img/ImGoD.jpg";
import navIcon1 from '../assets/img/instagram.png';
import navIcon2 from '../assets/img/tik-tok.png';
import navIcon3 from '../assets/img/twitch.png';
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const BannerMetodi = () => {
  

  return (
    
    <section className="bannerMetodi" id="home">
      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className="BannerMetodiLink">
                  <a href="/SondaggiRetribuiti"><h5>Sondaggi retribuiti</h5></a>
              </div>
              }
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className="BannerMetodiLink">
                  <a href="/AffiliateMarketing"><h5>Affiliate marketing</h5></a>
              </div>
              }
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}