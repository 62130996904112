
import { Container, Row, Col } from "react-bootstrap";
import uanama from "../assets/img/uanama.jpg";
import ImGoD from "../assets/img/ImGoD.jpg";
import navIcon1 from '../assets/img/instagram.png';
import navIcon2 from '../assets/img/tik-tok.png';
import navIcon3 from '../assets/img/twitch.png';
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const BannerAffiliateMarketing = () => {
  

  return (
    
    <section className="bannerSondaggiRetribuiti" id="home">
      <h1>Affiliate Marketing</h1>
      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className="BannerSondaggiRetribuitiLink">
                  <h3>Introduzione: </h3>
                  <br></br>
                  <h5>Ciao a tutti! Oggi voglio parlarvi dell'affiliazione marketing, una delle strategie più utilizzate per guadagnare denaro online:</h5>
                  <br></br>
                  <h5>Affiliate Marketing. </h5>
              </div>
              }
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className="BannerSondaggiRetribuitiLink">
                  <h3>In cosa consiste: </h3>
                  <br></br>
                  <h5> L'Affiliate Marketing consiste nel promuovere i prodotti di altre persone o aziende, guadagnando una commissione su ogni vendita effettuata grazie al tuo link di affiliazione.</h5>
              </div>
              }
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className="BannerSondaggiRetribuitiLink">
                  <h3>Come diventare affiliato: </h3>
                  <br></br>
                  <h5> Per diventare un affiliato, devi iscriverti ad un programma di affiliazione online e scegliere i prodotti che desideri promuovere.</h5>
                  <br></br>
                  <h5> Puoi scegliere tra una vasta gamma di prodotti e servizi, in base ai tuoi interessi e al tuo pubblico di riferimento.</h5>
                  <br></br>
                  <h5> Una volta scelto il prodotto, ti verrà fornito un link di affiliazione unico che dovrai condividere con il tuo pubblico attraverso il tuo blog, il tuo sito web o i tuoi social media.</h5>
                  
                  
              </div>
              }
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className="BannerSondaggiRetribuitiLink">
                  <h3>Ecco alcuni siti: </h3>
                  <br></br>
                  <h5>-Amazon Associates: <a href="https://affiliate-program.amazon.com/"><h5>https://affiliate-program.amazon.com/ </h5></a></h5>
                  <h5>-Amazon Associates ti consente di guadagnare una commissione promuovendo prodotti sul sito di Amazon. Puoi guadagnare dal 1% al 10% di commissione sulle vendite che generi.</h5>
                  <br></br>
                  <h5>-ClickBank: <a href="https://www.clickbank.com/"><h5>https://www.clickbank.com/ </h5></a></h5>
                  <h5>-ClickBank è una piattaforma di affiliate marketing che ti consente di promuovere prodotti digitali, come corsi online e ebook. Puoi guadagnare dal 50% al 75% di commissione sulle vendite che generi.</h5>
                  <br></br>
                  <h5>-ShareASale: <a href="https://www.shareasale.com/"><h5>https://www.shareasale.com/ </h5></a></h5>
                  <h5>-ShareASale è una piattaforma di affiliate marketing che ti consente di promuovere prodotti di varie categorie, tra cui moda, bellezza, salute e fitness. Puoi guadagnare una commissione in base alle condizioni specifiche del programma di affiliazione.</h5>
              </div>
              }
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className="BannerSondaggiRetribuitiLink">
                  <h3>Obiettivi dell'affiliate marketing: </h3>
                  <br></br>
                  <h5>-L'obiettivo dell'affiliazione marketing è quello di convincere il tuo pubblico ad acquistare il prodotto che promuovi, utilizzando il tuo link di affiliazione.</h5>
                  <br></br>
                  <h5>-Per ottenere risultati efficaci, è importante creare contenuti di alta qualità e utili per il tuo pubblico, che incoraggino l'acquisto del prodotto in modo naturale e genuino.</h5>
                  <br></br>
                  <h5>-Inoltre, è importante essere trasparenti riguardo alla tua relazione di affiliazione con l'azienda, in modo da mantenere la tua credibilità e la fiducia del pubblico.</h5>
              </div>
              }
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className="BannerSondaggiRetribuitiLink">
                  <h3>Vantaggi e svantaggi: </h3>
                  <br></br>
                  <h5>-Uno dei vantaggi dell'affiliazione marketing è che puoi guadagnare denaro senza dover creare o gestire un prodotto.</h5>
                  <br></br>
                  <h5>-Inoltre, puoi guadagnare denaro anche mentre dormi, poiché il tuo link di affiliazione è attivo 24 ore al giorno, 7 giorni alla settimana.</h5>
                  <br></br>
                  <h5>-Tuttavia, è importante scegliere i prodotti giusti da promuovere, per garantire una buona conversione delle vendite e ottenere una commissione adeguata.</h5>
              </div>
              }
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className="BannerSondaggiRetribuitiLink">
                  <h3>Conclusioni: </h3>
                  <h5>In conclusione, l'affiliate marketing è una strategia redditizia e accessibile per guadagnare denaro online. Per avere successo, è importante scegliere i prodotti giusti da promuovere, creare contenuti di alta qualità e utili per il tuo pubblico, e creare una strategia efficace di marketing digitale. Seguendo queste regole fondamentali, puoi guadagnare denaro online in modo sicuro e affidabile, senza dover creare o gestire un prodotto.</h5>
              </div>
              }
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}